<template>
    <div id="Recharge">
        <PageTitle title="在线充值" fixed
                   :backTo="this.payPreRoute == 'consultOnline' ? '/consultOnline/1/' + this.departmentId + '/' + this.doctorId : (this.payPreRoute == 'encore' ? '/encore/' +this.doctorId +'/1' : '/accountInformation/' + this.selectIndex + '/' + this.patientId)"></PageTitle>
        <div style="position: relative;width: 85%;margin: 15px auto auto auto;" v-show="spinnerShow == 0">
            <List :width="100">
                <ListItem>
                    <template #top>
                        <p style="margin-bottom: 10px">账户信息</p>
                        <p class="smallTxt">就诊卡号：{{idDetail.ckhm | filterCardNum}}</p>
                        <p class="smallTxt" style="width: 35%;display: inline-block;text-align: right">
                            姓名：{{idDetail.brxm}}</p>
                        <p class="smallTxt" style="width: 20%;display: inline-block;text-align: center;margin-left: 5%">
                            性别：{{idDetail.brxb}}</p>
                        <p class="smallTxt" style="width: 35%;display: inline-block;text-align: left;margin-left: 5%">
                            余额：{{idDetail.zhye}}</p>
                        <p class="smallTxt">住址：{{idDetail.address}}</p>
                    </template>
                </ListItem>
                <ListItem :leftWidth="50" :rightWidth="50">
                    <template #top>
                        <p style="margin-bottom: 10px">请选择充值金额</p>
                    </template>
                    <template #left>
                        <van-button type="primary" size="normal" block @click="recharge(10)">10元</van-button>
                        <van-button type="primary" size="normal" block @click="recharge(50)">50元</van-button>
                        <van-button type="primary" size="normal" block @click="recharge(300)">300元</van-button>
                    </template>
                    <template #right>
                        <van-button type="primary" size="normal" block @click="recharge(20)">20元</van-button>
                        <van-button type="primary" size="normal" block @click="recharge(100)">100元</van-button>
                        <van-button type="primary" size="normal" block @click="recharge(500)">500元</van-button>
                    </template>
                    <template #bottom>
                        <van-field v-model="money" label="充值金额" type="number" placeholder="具体数值" input-align="center"/>
                    </template>
                </ListItem>
            </List>
            <van-button type="primary" block @click="rechargeTips">确认充值</van-button>
        </div>
        <van-loading type="spinner" style="margin: 50%" v-show="spinnerShow == 1"/>
    </div>
</template>

<script>
    import PageTitle from '@/components/PageTitle.vue';
    import List from '@/components/home-page/List.vue';
    import ListItem from '@/components/home-page/ListItem.vue';
    import wx from 'weixin-js-sdk';
    import {Pay, QueryAccount, PayCheck} from '@/api/user.js';
    export default{
        components: {
            PageTitle,
            List,
            ListItem,
        },
        data(){
            return {
                idDetail: {
                    id: 0,
                    ckhm: "",
                    brxm: "",
                    brxb: "",
                    sfzh: "",
                    brxz: "",
                    xzmc: "",
                    zhye: 0,
                    ktye: 0,
                    mrzh: "",
                    sjhm: "",
                    address: "",
                    nl: "",
                    birthday: "",
                    mz: "",
                    zhyxpb: "",
                    sfzpb: "",
                    phonePB: "",
                    namepb: "",
                    srbrxm: "",
                    srsfzh: "",
                    srphone: ""
                },
                postData: {
                    openId: "",
                    ip: "172.0.0.1",
                    patientName: "",
                    mzhm: "",
                    ckhm: "",
                    attach: "",
                    fee: 0
                },
                money: '',
                spinnerShow: 1,
                cardId: localStorage.getItem('payCardId'),//就诊卡号
                payPreRoute: localStorage.getItem('payPreRoute'),//上一个界面的name
                departmentId: localStorage.getItem('payDepartmentId'),//云门诊route附带参数
                doctorId: localStorage.getItem('payDoctorId'),//云门诊route附带参数
                selectIndex: localStorage.getItem('selectIndex'),//个人账户详情route附带参数
                encoreParams: localStorage.getItem('encoreParams'),//再来一单route附带参数
                patientId: localStorage.getItem('payPatinetId'),//再来一单route附带参数
            };
        },
        mounted(){
            if(localStorage.getItem('pdtj')){
                window.location.replace(window.location.origin+window.location.search+"#/consult");
            }
            else {
                if (this.$route.params.status) {
                    this.getCardInfo();
                }
                else {
                    setTimeout(() => {
                        this.getCardInfo();
                    }, 2500);
                }
            }
        },
        destroyed(){
            setTimeout(() => {
                QueryAccount(3, localStorage.getItem('payCardId'), this.$store.getters.userInfo.UserId).then(
                    (data) => {
                        this.$store.dispatch('pageData/setInfo', data[0]);
                    }, () => {
                    }
                );
            }, 200);
        },
        methods: {
            getCardInfo(){
                this.spinnerShow = 0;
                if (localStorage.getItem('payOpenId')) {
                    this.postData.openId = localStorage.getItem('payOpenId');
                    this.$store.dispatch('user/setOpenId', localStorage.getItem('payOpenId'));
                }
                else {
                    localStorage.setItem('payOpenId', this.$store.getters.openId);
                    this.postData.openId = this.$store.getters.openId;
                }
                QueryAccount(3, localStorage.getItem('payCardId'), this.$store.getters.userInfo.UserId).then(
                    (data) => {
                        this.$store.dispatch('pageData/setInfo', data[0]);
                        this.idDetail = data[0];
                        this.postData.patientName = data[0].brxm;
                        this.postData.mzhm = data[0].mzhm;
                        this.postData.ckhm = data[0].ckhm;
                    },
                    () => {
                    }
                );
            },
            recharge(money){
                this.money = money;
            },
            pay(postData){
                Pay(postData).then(
                    (result) => {
                        localStorage.setItem('pdtj', "");
                        wx.miniProgram.postMessage({data: 'res_id值'})
                        wx.miniProgram.redirectTo({url: '/pages/webview/webview?res=' + result})
//                        wx.miniProgram.navigateTo({url: '/pages/webviewresult/webviewresult?res=' + result})
                    },
                    () => {
                    }
                );
            },
            rechargeTips() {
                if (this.money >= 0.01 && this.money <= 20000) {
                    this.postData.fee = Number(this.money);
                    this.postData.type = this.$store.getters.medicalAssistant ? 1:0;
					PayCheck(this.idDetail.sfzh).then(
						(result) => {
							if (result == 'Y') {
								this.$toast('该患者为住院状态，请前往就医小助手充值到住院账户');
							} else {
								this.pay(this.postData);
							}
						},
						() => {
						}
					)
                }
                else {
                    this.$toast('请输入0.01~20000之间的金额');
                }
//                this.$dialog.alert({
//                    title: '温馨提醒',
//                    message: '充值成功\n您当前账户预存金额为：800元',
//                    confirmButtonText: '确定',
//                }).then(() => {
//                    // on confirm
//                    if(this.$route.query.backPath == 'consultOnline'){
//                        this.$router.push('/consultOnline/1/' + this.$route.params.departmentId + '/' + this.$route.params.doctorId);
//                    }else{
//                        this.$router.push('/accountInformation');
//                    }
//                });
            }
        }
    }
</script>

<style scoped="" lang="less">
    @import "../style/var.less";

    .infoContainer {
        padding: 10px;

        .top p {
            text-align: center;
            margin: 0;
        }

        .top .smallTxt {
            font-size: @font-size-sm;
        }

        .left .van-button, .right .van-button {
            margin: 0 0 10px;
        }

        .van-cell {
            border: 1.5px solid @green;
            padding: 4px 16px;
            border-radius: 8px;
            box-shadow: 0px 0px 5px 0px @gray-6 inset;

        }
    }
</style>